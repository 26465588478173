import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GraingerBar, NotFound, Loading, BlogPost, OneTrustCookieBanner } from 'grainger-component-library';
import { oneTrustProps } from '../../config/constants';

import cms from '../../providers/cms/cms';
import { trackPage } from '../../providers/analytics/analytics';
import * as PrismicHelpers from '../../providers/cms/helpers/helpers';

import { Head } from '../../components/Head/Head';

class BlogPostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      footer: [],
      subContent: {},
      error: false,
      statusMessage: 'Loading...',
      theme: null,
      loadingItems: [],
      metaData: {
        title: null,
        description: null,
        image: {
          url: null,
          twitter: null
        },
      },
    };

    this.processLoadingResult = this.processLoadingResult.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const apiComplete = new Promise(resolve => resolve());

    this.setState({
      loadingItems: [
        this.windowLoaded(),
        cms.getPage(this.props)
      ]
    });

    cms.getPage(this.props).then((page) => {
      this.setState({
        title: page.main.title,
        mainContent: page.main,
        subContent: page.sub,
        metaData: {
          title: PrismicHelpers.plainText(page.main.meta.meta_title),
          description: PrismicHelpers.plainText(page.main.meta.meta_description),
          image: page.main.meta.meta_image ? {
            url: page.main.meta.meta_image.url || undefined,
            twitter: page.main.meta.meta_image.Twitter ? page.main.meta.meta_image.Twitter.url : undefined,
          } : {
            url: undefined,
            twitter: undefined,
          },
        },
        theme: page.main.meta.theme,
        loadingItems: [apiComplete]
      });
    }).catch((error) => {
      this.setState({
        subContent: error.sub,
        title: error.message,
        error: true,
      });
      return new Error(error);
    });

    trackPage(`${location.pathname}${location.search}`);
  }

  render() {
    const {
      title, mainContent, subContent, statusMessage, error, theme
    } = this.state;

    if (error) return <div className="gr-not-found--finzels"><NotFound /></div>;

    const _theme = PrismicHelpers.themePicker(theme);
    if (_theme) {
      import(`grainger-component-library/build/themes/${_theme}.css`).then(() => {
        // eslint-disable-next-line no-console
        console.log(`%c Theme ${theme} will be applied.`, 'background: #333; color: #bd5');
      }).catch(() => {
        // eslint-disable-next-line no-console
        console.info(`%c  There is no ${theme} theme. No theme will be applied.`, 'background: #333; color: #f45');
      });
    }

    const nav = (subContent && 'navigation' in subContent) ? subContent.navigation : null;
    const barData = (subContent && 'bar' in subContent) ? subContent.bar : null;
    const footer = (subContent && 'footer' in subContent) ? subContent.footer : null;

    return (
      <React.Fragment>
        <Head {...this.state.metaData} />

        <OneTrustCookieBanner
          testScriptSource={oneTrustProps.testScriptSource}
          scriptSource={oneTrustProps.scriptSource}
          isProduction={oneTrustProps.isProduction}
        />

        <Loading
          page={this.props.location.pathname}
          onResult={this.processLoadingResult}
          loadingItems={this.state.loadingItems}
          timeout={10000}
        />

        <div className={`${_theme} ${!this.state.loadingComplete ? 'loading-incomplete' : ''}`} data-theme={_theme}>
          {barData &&
            <GraingerBar
              link={PrismicHelpers.link(barData.url, barData.text)}
            />
          }

          {nav && nav.components && nav.components.map((pageNavigation) => {
            return (
              <pageNavigation.component key={pageNavigation.key} {...pageNavigation.props} />
            )
          })}

          <main className={`${!error ? 'page-wrapper' : ''} js-hg-content`} id="content">
            <h1 className="sr-only">{title}</h1>
            {mainContent
              ? <BlogPost
                title={PrismicHelpers.plainText(mainContent.page_title)}
                subtitle={PrismicHelpers.plainText(mainContent.subtitle)}
                image={mainContent.image && mainContent.image.url}
                date={mainContent.date}
                body={PrismicHelpers.richText(mainContent.body)}
                authorImage={mainContent.author_image && mainContent.author_image.url}
                authorName={PrismicHelpers.plainText(mainContent.author_name)}
                authorTitle={PrismicHelpers.plainText(mainContent.author_title)}
                blogFeedUrl="/blog"
                blogPostUrl={window && window.location.href}
              />
              : <div style={{padding: '200px 30px 30px', minHeight: '600px'}}>{statusMessage}</div>
            }
          </main>

          {footer &&
            <div className="footer">
              {footer.components && footer.components.map(pageFooter => (
                <pageFooter.component key={pageFooter.key} {...pageFooter.props} />
              ))}
            </div>
          }
        </div>
      </React.Fragment>
    );
  }

  processLoadingResult(result) {
    if (!result) {
      this.setState({ error: 'Error loading page' });
    } else {
      this.setState({ loadingComplete: true });
    }
  }

  windowLoaded() {
    return new Promise((resolve) => {
      if(!window.loaded) {
        window.onload = resolve;
      } else {
        resolve();
      }
      window.loaded = true;
    });
  }
}

BlogPostPage.propTypes = {
  location: PropTypes.shape(),
};

BlogPostPage.defaultProps = {
  location: null,
};

export default BlogPostPage;
