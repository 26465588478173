import ReactGA from 'react-ga';
import {GA_TRACKING, appEnvironment} from "../../config/constants";

const GlobalConfig = {
  analytics: {
    trackId: GA_TRACKING,
    gaConfig: {
      debug: appEnvironment !== 'production',
      alwaysSendReferrer: true,
      allowLinker: true,
    },
  }
}

export const configureAnalytics = () => {
  if (GlobalConfig.analytics.trackId) {
    ReactGA.initialize(GlobalConfig.analytics.trackId, GlobalConfig.analytics.gaConfig);
  }
};

export const trackPage = (page = '') => {
  if (GlobalConfig.analytics.trackId && page && typeof page === 'string') {
    ReactGA.pageview(page);
  }
};
