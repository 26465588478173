import React from 'react';
import './index.css';
import Router from './config/router';

import {configureAnalytics} from './providers/analytics/analytics';

configureAnalytics();

const App = () => <Router/>;

export default App;
