import React from 'react'
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import socialMetaImage from '../../assets/images/meta-image-default.jpg';

export const Head = ({title, description, image}) => (
  <Helmet>
    <title>{title}</title>

    <link rel="canonical" href={window.location.origin + window.location.pathname} />

    <meta name="description" content={description} />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@graingerplc" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image.twitter} />

    <meta property="og:title" content={title} />
    <meta property="og:url" content={window.location.origin + window.location.pathname} />
    <meta property="og:image" content={image.url} />
    <meta property="og:description" content={description} />
    <meta property="og:site_name" content={title} />
  </Helmet>
);

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    twitter: PropTypes.string,
  }),
};

Head.defaultProps = {
  title: null,
  description: null,
  image: {
    url: socialMetaImage,
    twitter: socialMetaImage
  }
};

export default Head;
