import React from 'react';
import { Switch, Router, Route } from 'react-router';
import createHistory from 'history/createBrowserHistory';
import { NotFound } from 'grainger-component-library';

import Page from '../pages/Page/Page';
import BlogFeedPage from '../pages/BlogFeedPage/BlogFeedPage';
import BlogPostPage from '../pages/BlogPostPage/BlogPostPage';
import Preview from '../pages/Preview/Preview';
import Contact from '../pages/Contact/Contact';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';

/**
 * Initialise the history
 */
export const history = createHistory();

/**
 * Define and export the routes
 */
export default () => (
  <Router history={history}>
    <ScrollToTop>
      <Switch>
        <Route exact path="/contact" render={routeProps => <Contact {...routeProps} />} />
        <Route exact path="/preview" render={routeProps => <Preview {...routeProps} />} />
        <Route exact path="/blog" render={routeProps => <BlogFeedPage {...routeProps} />} />
        <Route exact path="/blog/:uid" render={routeProps => <BlogPostPage key={routeProps.location.pathname} {...routeProps} />} />
        <Route exact path="/" render={routeProps => <Page key={'/'} {...routeProps} />} />
        <Route exact path="/:uid" render={routeProps => <Page key={routeProps.location.pathname} {...routeProps} />} />
        <Route component={NotFound} />
      </Switch>
    </ScrollToTop>
  </Router>
);
